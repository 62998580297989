// const mql = window.matchMedia(`(min-width: 992px)`)
import * as firebase from "firebase/app"
import React from "react"
import { connect } from "react-redux"
import Sidebar from "react-sidebar"
import "../../../assets/scss/pages/app-chat.scss"
import { changecurrenchatuser } from "../../../redux/actions/chat/index"
import { ContextLayout } from "../../../utility/context/Layout"
import ChatLog from "./ChatLog"
import ChatSidebarContent from "./ChatSidebar"
var oldcombineid = null;

class Chat extends React.Component {
  state = {
    userProfile: false,
    sidebarDocked: false,
    sidebarOpen: true,
    activeUser: null,
    receiverProfile: false,
    userSidebar: false,
    currentmessages: null,
  }
  // mounted = false
  handleUserSidebar = status => {
    if (status === "open") {
      this.setState({
        userProfile: true
      })
    } else {
      this.setState({
        userProfile: false
      })
    }
  }
  componentDidUpdate(preprop, prestate) {
    if (this.props.currentchatuser !== preprop.currentchatuser) {
      if (this.props.currentchatuser) {
        var user = this.props.currentchatuser;
        console.log("Current user changed");
        var id = user.id;
        console.log("Getting old messages");
        this.setState({
          activeUser: this.props.currentchatuser,
          sidebarOpen: false,
          currentmessages: null
        })
        if (oldcombineid) {
          console.log(oldcombineid);
          firebase.database().ref("chats/" + oldcombineid).off('child_added')
          // chatlistner.off('child_added');
        }
        var cid = firebase.auth().currentUser.uid;
        var combineid = ""
        if (id > cid) {
          combineid = cid + "_" + id;
        } else {
          combineid = id + "_" + cid;
        }
        console.log(combineid);
        oldcombineid = combineid;

        var initialLoadDone = false;
        firebase.database().ref("chats/" + combineid).on("child_added", (added) => {
          if (initialLoadDone) {
            console.log("Added new chat");
            console.log(added.val());
            if (added.val().uid !== cid) {
              console.log("Setting Unseen to 0");
              if (this.props.cState) {
                firebase.database().ref("chatd/" + cid + "/" + id + "/us").set(0);
              }
            }
            var joined = this.state.currentmessages.concat({ id: added.key, ...added.val() });
            this.setState({
              currentmessages: joined
            })
          }
        })
        firebase.database().ref("chats/" + combineid).once("value", (snap) => {
          console.log("Testing chat1232123")
          initialLoadDone = true;
          if (snap.exists()) {
            firebase.database().ref("chatd/" + cid + "/" + id + "/us").set(0);
            var data = snap.val();
            console.log("Got initial chat data");
            var initialchatdata = [];
            Object.keys(data).map(d => {
              initialchatdata.push({
                id: d,
                ...data[d]
              })
            })
            this.setState({
              currentmessages: initialchatdata
            })
          } else {
            console.log("First time chat");
            this.setState({
              currentmessages: []
            })
          }
          console.log(snap.val());
        }).catch(err => {
          console.log(err);
        })
      } else {
        if (oldcombineid) {
          console.log(oldcombineid);
          firebase.database().ref("chats/" + oldcombineid).off('child_added');

          // chatlistner.off('child_added');
        }
        this.setState({
          activeUser: null,
          sidebarOpen: true
        })
      }
    }
  }
  handleActiveChat = (user) => {
    this.props.changecurrenchatuser(user);
    this.setState({
      activeUser: user,
      currentmessages: null
    })
    // console.log(id);
    // console.log(user);

  }



  onSetSidebarOpen = open => {
    this.setState({ sidebarOpen: open })
  }

  // mediaQueryChanged = () => {
  //   this.setState({ sidebarDocked: mql.matches, sidebarOpen: false })
  // }

  handleReceiverSidebar = status => {
    status === "open"
      ? this.setState({
        receiverProfile: true
      })
      : this.setState({
        receiverProfile: false
      })
  }

  handleUserSidebar = status => {
    status === "open"
      ? this.setState({
        userSidebar: true
      })
      : this.setState({
        userSidebar: false
      })
  }

  render() {
    return (
      <div className="chat-application position-relative">
        <div
          className={`chat-overlay ${
            this.state.receiverProfile ||
              this.state.userSidebar ||
              this.state.sidebarOpen
              ? "show"
              : "d-none"
            }`}
        // onClick={() => {
        //   this.handleReceiverSidebar("close")
        //   this.handleUserSidebar("close")
        //   this.onSetSidebarOpen(false)
        // }}
        />
        <ContextLayout.Consumer>
          {context => (
            <Sidebar
              sidebar={
                <ChatSidebarContent
                  activeChatID={this.state.activeUser ? this.state.activeUser.id : null}
                  handleActiveChat={this.handleActiveChat}
                  // handleUserSidebar={this.handleUserSidebar}
                  mainSidebar={this.onSetSidebarOpen}
                />
              }
              docked={this.state.sidebarDocked}
              open={this.state.sidebarOpen}
              touch={false}
              sidebarClassName="chat-sidebar"
              contentClassName="sidebar-children d-none"
              pullRight={context.state.direction === "rtl"}>
              ""
            </Sidebar>
          )}
        </ContextLayout.Consumer>
        {/* <UserSidebar
          userProfile={this.state.userSidebar}
          handleUserSidebar={this.handleUserSidebar}
        /> */}
        {this.state.activeUser ?
          <ChatLog
            // activeChatID={this.state.activeChatID}
            activeUser={this.state.activeUser}
            // handleReceiverSidebar={this.handleReceiverSidebar}
            mainSidebar={this.onSetSidebarOpen}
            messages={this.state.currentmessages}

            // mql={mql}
            handleActiveChat={this.handleActiveChat}
          /> : null}

      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    cState: state.customizer.customizer.customizerState,
    currentchatuser: state.chatApp.chats.currentchatuser
  }
}
export default connect(mapStateToProps, { changecurrenchatuser })(Chat)
