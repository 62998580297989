import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "Easy Navigation",
  },
  // {
  //   id: "welcome",
  //   title: "Welcome Note",
  //   modaltype: "video",
  //   modalsrc: "https://www.youtube.com/watch?v=zzmQAA4e0f4",
  //   type: "modal",
  //   icon: <Icon.Calendar size={16} />
  // },
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/",
    // permissions: ["User"],
  },
  // {
  //   id: "approve",
  //   title: "Announcement",
  //   type: "item",
  //   icon: <Icon.Airplay size={20} />,
  //   navLink: "/notification",
  // },
  {
    id: "reject",
    title: "Manage Users",
    type: "item",
    icon: <Icon.Airplay size={20} />,
    navLink: "/users",
    permissions: ["sa", "a"],
  },
  {
    id: "reject1",
    title: "Invoice Track",
    type: "item",
    icon: <Icon.Airplay size={20} />,
    navLink: "/invoicetrack"
  },
  {
    id: "reject3",
    title: "Claims Track",
    type: "item",
    icon: <Icon.Airplay size={20} />,
    navLink: "/gifttrack"
  },
  // {
  //   id: "reject",
  //   title: "Transactions",
  //   type: "item",
  //   icon: <Icon.Airplay size={20} />,
  //   navLink: "/transaction",
  //   // permissions: ["sa", "a"],
  // },
  // {
  //   id: "exhibition1",
  //   title: "DC Excellence Showcase - Zone 1",
  //   type: "item",
  //   icon: <Icon.Grid size={20} />,

  //   navLink: "/zone1",
  // },
  // {
  //   id: "exhibition2",
  //   title: "DC Excellence Showcase - Zone 2",
  //   type: "item",
  //   icon: <Icon.Grid size={20} />,

  //   navLink: "/zone2",
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "Information"
  // },
  // {
  //   id: "agenda",
  //   title: "Agenda",
  //   modaltype: "pdf",
  //   modalsrc: "/images/agenda.pdf",
  //   type: "modal",
  //   icon: <Icon.List size={20} />,
  // },
  // {
  //   id: "resource",
  //   title: "Document Library",
  //   type: "modal",
  //   modaltype: "docs",
  //   modalsrc: "",
  //   data: [{
  //     name: "ISA WSTS- Concept Paper",
  //     url: "/pdf/ISA WSTS- Concept Paper.pdf",
  //     // url: "https://drive.google.com/file/d/1wHdrol5BQIyMnf8p6I1-QrMhWPHchxBj/preview"
  //   }
  //   ],
  //   icon: <Icon.File size={20} />,
  // },
  // {
  //   id: "Auditorium2",
  //   title: "HR ROADSHOW 2020",
  //   type: "item",
  //   icon: <Icon.Award size={20} />,
  //   navLink: "/auditorium2",
  // },
  // {
  //   id: "exhibition",
  //   title: "Exhibition Area ",
  //   type: "item",
  //   icon: <Icon.Grid size={20} />,
  //   navLink: "/exhibitors/page1",
  // },

  // {
  //   id: "helpdesk",
  //   title: "Support",
  //   modaltype: "pdf",
  //   modalsrc: "https://tawk.to/chat/5f4fc419f0e7167d000ce4bf/1eh9anelb",
  //   type: "modal",
  //   icon: <Icon.HelpCircle size={20} />,
  //   // navLink: "/helpdesk",
  // },

  // {
  //   type: "groupHeader",
  //   groupTitle: "Fun"
  // },
  // {
  //   id: "funzone",
  //   title: "Family Fun Zone ",
  //   type: "item",
  //   icon: <Icon.Star size={20} />,
  //   navLink: "/funzone",
  // },
  // {
  //   id: "photo",
  //   title: "Photobooth",
  //   type: "external-link",
  //   newTab:true,
  //   icon: <Icon.Crop size={20} />,
  //   navLink: "https://micronhrroadshow.web.app/photobooth/index.html",
  // },
  // {
  //   id: "car",
  //   title: "Game Room",
  //   modaltype: "car_rush",
  //   modalsrc: "https://tawk.to/chat/5f4c11f21e7ade5df4453f1c/default",
  //   type: "modal",
  //   icon: <Icon.HelpCircle size={20} />,
  // }
  // {
  //   id: "facebook",
  //   title: "Photo Mosaic ",
  //   type: "external-link",
  //   newTab:true,
  //   icon: <Icon.Image size={20} />,
  //   navLink: "https://pwcimprints.web.app/photo_mosaic/index.html",
  // },
  // {
  //   id: "leaderboard",
  //   title: "Leaderboard",
  //   type: "item",
  //   icon: <Icon.Clipboard size={20} />,
  //   navLink: "/leaderboard",
  // },
];

export default navigationConfig;
