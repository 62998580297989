import React, { Component } from "react";
import {
  // Modal,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  // Tooltip
} from "reactstrap";
import { connect } from "react-redux"
import { customizerState } from '../../redux/actions/customizer'
import { setGlobalModal } from '../../redux/actions/nyucaction'
import { changecurrenchatuser } from "../../redux/actions/chat/index"

import ReactPlayer from 'react-player'
// import ImageMapper from './ImageMapper';
// import ResizeDetector from 'react-resize-detector';
import Spinner from "../../components/@vuexy/spinner/Loading-spinner"
import Modalview from "./Modalview";
import Tooltipview from './Tooltipview';
import { history } from "../../history"
import { isMobile } from 'react-device-detect'
class Mapperview extends Component {
  state = {
    spinner: true,
    modal: false,
    modalsrc: "",
    modaltype: "",
    mapwidth: null,
    modaldata: {},
    isloading: true,
    agendadata: null,
    modalclass: null
  };

  render() {
    return (
      <div>
        <Modalview openmodal={this.state.modal} agendadata={this.state.agendadata} redirecttopage={(url, videolink) => {
          this.setState({
            modal: false
          });
          console.log("Redirecting to:- " + url);
          if (url && url.length > 0) {
            history.push({
              pathname: url,
              videolink: videolink
            })
          }
        }} toggleModal={() => {
          this.setState(prevState => ({
            modal: !prevState.modal
          }))
        }} modalheader={this.state.modalheader} modalclass={this.state.modalclass} modaldata={this.state.modaldata} modalsrc={this.state.modalsrc} modaltype={this.state.modaltype} />

        <Tooltipview data={this.props.mapdata.areas} />
        <div style={{ position: "relative" }}>
          {this.state.spinner ?
            <Spinner /> : null}
          {this.props.mapdata.video && !isMobile ?
            <ReactPlayer className='react-player' playing muted loop width='100%'
              height='100%'
              onReady={() => {
                this.props.onloadcall();
                this.setState({
                  spinner: false
                })
              }}
              style={{ position: "relative" }}
              url={this.props.mapdata.url} />
            :
            <img width='100%'
              height='100%'
              alt={"clickable"}
              onLoad={() => {
                this.props.onloadcall();
                this.setState({
                  spinner: false
                })
              }}
              style={{ position: "relative" }} src={this.props.mapdata.video ? this.props.mapdata.videoframe : this.props.mapdata.url} />
          }
          {/* <video id="walkingPeopleVideo" autoplay="" loop="" src="https://cdn-akamai.6connex.com//341/1057/1515186169770_22_Lobby Alpha.webm"></video> */}

          {this.props.mapdata.areas.map(a => {

            if (a.linktype === "inside") {
              return (
                <React.Fragment key={a.id}>
                  {this.state.isloading ?
                    <Spinner /> : null}
                  <iframe
                    src={a.url}
                    title={"Title"}
                    onLoad={() => {
                      this.setState({ isloading: false })
                    }}
                    allowFullScreen="allowfullscreen"
                    allow="autoplay; encrypted-media;geolocation; microphone; camera"
                    id={a.id}
                    style={{
                      cursor: "pointer",
                      zIndex: 2,

                      transform: `skew(${a.skew ? a.skewx : "0"}deg,${a.skew ? a.skewy : "0"}deg)`,
                      position: "absolute", width: a.coords.width, height: a.coords.height, top: a.coords.top, left: a.coords.left
                    }}></iframe>
                </React.Fragment >
              )
            } else if (a.linktype === "videoo") {
              return (
                <video style={{
                  zIndex: 1,
                  cursor: "pointer",
                  transform: `skew(${a.skew ? a.skewx : "0"}deg,${a.skew ? a.skewy : "0"}deg)`,
                  position: "absolute", width: a.coords.width, height: a.coords.height, top: a.coords.top, left: a.coords.left
                }} id="walkingPeopleVideo" muted="true" autoplay="true" loop="true" src={"https://cdn-akamai.6connex.com//341/1057/1515186169770_22_Lobby Alpha.webm"}></video>
              )
            } else {

              return (
                <div key={a.id}
                  onClick={() => {
                    console.log(a);
                    if (a.linktype === "link") {
                      history.push(a.url);
                    } else if (a.linktype === "elink") {
                      window.open(a.url, "_blank")
                    } else if (a.linktype === "chat") {
                      console.log("Open chat");
                      console.log(this.props);
                      this.props.customizerState(true);
                      this.props.changecurrenchatuser(a.chatdata);
                    } else if (a.linktype === "playvideo") {
                      if (isMobile) {
                        history.push(a.url);
                      } else {
                        this.props.playvideo(a);
                      }
                    } else if (a.linktype === "globalmodal") {
                      console.log("Open globalmodal");
                      console.log(a);
                      console.log(this.props);
                      this.props.setGlobalModal({
                        state: true,
                        type: a.url,
                        data: {
                          header: a.name
                        }
                      });
                    } else {
                      this.setState({
                        modalheader: a.name,
                        modalsrc: a.url,
                        modaldata: a.data ? a.data : {},
                        modalclass: a.modalclass,
                        modal: true,
                        modaltype: a.linktype,
                        agendadata: a.agendadata,
                      })
                    }
                  }}
                  id={a.id}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: `skew(${a.skew ? a.skewx : "0"}deg,${a.skew ? a.skewy : "0"}deg)`,
                    position: "absolute", width: a.coords.width, height: a.coords.height, top: a.coords.top, left: a.coords.left
                  }}><span className={` ${a.noblinking ? "" : a.blinkingclass ? "blinkingdot2" : "blinkingdot"}`}></span> </div>
              )
            }
          })}
        </div>
      </div >
    );
  }
}


const mapStateToProps = state => {
  return {
    autoplay: state.auth.login.autoplay
  }
}
// export default ShopContent;
export default connect(mapStateToProps, {
  customizerState,
  setGlobalModal,
  changecurrenchatuser
}, null, { forwardRef: true })(Mapperview);