// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
import 'firebase/functions'

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  console.log = () => {};

}
const config ={
  apiKey: "AIzaSyBXH9icwJy-NT318coYxKjFyAJdQXXSozs",
  authDomain: "eatonedge.firebaseapp.com",
  databaseURL: "https://eatonedge-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "eatonedge",
  storageBucket: "eatonedge.appspot.com",
  messagingSenderId: "1049002727664",
  appId: "1:1049002727664:web:05e5511b961a4262a64a35",
  measurementId: "G-BQ3DDLYBK7"
};
firebase.initializeApp(config)
firebase.analytics();

