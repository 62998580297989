import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
// import "firebase/firestore"
import "firebase/storage";

import { history } from "../../../history";
import { JsonWebTokenError } from "jsonwebtoken";
// import { config } from "../../../authServices/firebase/firebaseConfig"
// console.log("hello5");
// // Init firebase if not already initialized
// if (!firebase.apps.length) {
//   firebase.initializeApp(config)
// }
console.log("In loginactions");
let firebaseAuth = firebase.auth();
var initialchat = false;
export const publicdataofuser = (uid) => {
  return new Promise((resolve, reject) => {
    var id = new Date().getTime();
    console.time(id);
    firebase
      .firestore()
      .collection("pub_user")
      .doc(uid)
      .get()
      .then((snap) => {
        console.timeEnd(id);
        resolve(snap.data());
      })
      .catch((err) => {
        reject(err);
        console.log(err);
      });
  });
};
export const getleaderboard = (user) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("pub_user")
        .orderByChild("total")
        .once(
          "value",
          function (snap) {
            var stat = [];
            snap.forEach(function (child) {
              // console.log(child.key); // 1498230632003..., 1498230632000...
              stat.push({ id: child.key, ...child.val() });
            });
            resolve({ e: true, data: stat.reverse() });
          },
          (error) => {
            resolve({ e: false });
            console.log("Error getting document:", error);
          }
        );
      // var docRef = firebase.firestore().collection("pub_user").orderBy("total", "desc")

      // docRef.get().then((querySnapshot) => {
      //   const tempDoc = []
      //   querySnapshot.forEach((doc) => {
      //     tempDoc.push({ id: doc.id, ...doc.data() })
      //   })
      //   console.log(tempDoc);
      //   resolve({ e: true, data: tempDoc });
      //   // if (doc.exists) {
      //   //   resolve({ e: true });
      //   //   console.log("Document data:", doc.data());
      //   // } else {
      //   //   resolve({ e: false });
      //   //   // doc.data() will be undefined in this case
      //   //   console.log("No such document!");
      //   // }
      // }).catch(function (error) {
      //   resolve({ e: false });
      //   console.log("Error getting document:", error);
      // });
    });
  };
};
function getuserlist(list, callback) {
  console.time("tet1");
  let userRefs = list.map((id) => {
    return firebase.firestore().collection("pub_user").doc(id).get();
  });
  Promise.all(userRefs)
    .then((docs) => {
      console.timeEnd("tet1");
      var users = {};
      docs.forEach((doc) => {
        users[doc.id] = doc.data();
      });
      callback({ error: 0, data: users });
    })
    .catch((error) => callback({ error: 1, err: error }));
}
function startfirebaselistner(uid, dispatch) {
  firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("chatd")
    .onSnapshot((snapshot) => {
      var intialchatdata = [];
      snapshot.docChanges().forEach(function (change) {
        if (change.type === "added") {
          if (!initialchat) {
            intialchatdata.push(change.doc.id);
          }
          console.log("New city: ", change.doc.data());
        }
        if (change.type === "modified") {
          console.log("Modified city: ", change.doc.data());
        }
        if (change.type === "removed") {
          console.log("Removed city: ", change.doc.data());
        }
      });
      if (!initialchat) {
        initialchat = true;
        console.log(intialchatdata);
        console.log("Getting user ids");
      }
    });
  // firebase.database().ref("chatd/" + uid).once('value', function (snapshot) {
  //   console.log(snapshot.val());
  //   getuserlist(Object.keys(snapshot.val()), (data) => {
  //     console.log("Getting user list");
  //     console.log(data)
  //   })
  //   firebase.database().ref("chatd/" + uid).on('child_changed', function (snapchange) {
  //     console.log("Child Changed");
  //     console.log(snapchange.key);
  //     console.log(snapchange.val());
  //   });
  //   firebase.database().ref("chatd/" + uid).on('child_added', function (snapadded) {
  //     console.log("Child Added");
  //     console.log(snapadded)
  //     publicdataofuser(snapadded.key).then(data => {
  //       console.log(data);
  //     }).catch(err => {
  //       console.log(err);
  //     })
  //     console.log(snapadded.key);
  //     console.log(snapadded.val());
  //   });
  //   dispatch({ type: "CHANGE_ROLE", userRole: "admin" });
  //   // updateStarCount(postElement, snapshot.val());
  // });
}
export const profilesavedone = (finald) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log("In profile svae done");
      console.log(finald);
      var finaldata = { publicdata: finald.publicdata, claims: finald.claims, };
      resolve({ e: 0, type: 0 });
      dispatch({ type: "FIREBASE_DATA", data: finaldata });
      dispatch({ type: "CHANGE_ROLE", userRole: finald.claims.status });
    });
  };
};
export const updatepublicdata = (data) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_FIREBASE_DATA", data: data });
  };
};

export const getpublicdataofuser = (user, claims) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // var finaldata = {
      //   publicdata: {
      //     status: "status" in claims ? claims.status : "User",
      //     name: "status" in claims ? claims.status : "User",
      //   },
      // };
      // resolve({ e: 0, type: 0 });
      // dispatch({ type: "FIREBASE_DATA", data: finaldata });
      // dispatch({ type: "CHANGE_ROLE", userRole: "admin" });
      // dispatch({
      //   type: "LOGIN_WITH_EMAIL",
      //   payload: {
      //     email: user.email,
      //     uid: user.uid,
      //     isSignedIn: true,
      //     loggedInWith: "firebase",
      //   },
      // });
      firebase
        .database()
        .ref("pub_user/" + user.uid)
        .once("value", function (snap) {
          firebase
            .database()
            .ref("claims/" + user.uid)
            .once("value", function (snapclaims) {
              console.log("in getpublicdataofuser");
              if (snap.exists()) {
                console.log("got initial public data");
                console.log(snap.val());
                var claims = snapclaims.val();
                var finaldata = {
                  publicdata: snap.val(),
                  claims: snapclaims.val(),
                };
                resolve({ e: 0, type: 0 });
                dispatch({ type: "FIREBASE_DATA", data: finaldata });
                dispatch({
                  type: "CHANGE_ROLE",
                  userRole: "status" in claims ? claims.status : "User",
                });
                dispatch({
                  type: "LOGIN_WITH_EMAIL",
                  payload: {
                    email: user.email,
                    uid: user.uid,
                    isSignedIn: true,
                    loggedInWith: "firebase",
                  },
                });
              } else {
                resolve({ e: 0, type: 1 });
                dispatch({
                  type: "LOGIN_WITH_EMAIL",
                  payload: {
                    email: user.email,
                    uid: user.uid,
                    isSignedIn: true,
                    loggedInWith: "firebase",
                  },
                });
              }
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
};
// const initAuth0 = new auth0.WebAuth(configAuth)
export const initialuserfound = (user) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.time("firestoretest2");
      console.log("In initialuserfound1");
      let userRefs = [];
      userRefs.push(
        firebase.firestore().collection("events").doc("virtual").get()
      );
      userRefs.push(
        firebase.firestore().collection("users").doc(user.uid).get()
      );
      userRefs.push(
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .collection("private")
          .doc("data")
          .get()
      );
      Promise.all(userRefs)
        .then((docs) => {
          console.timeEnd("firestoretest2");
          var finaldata = {
            eventdata: docs[0].data(),
            publicdata: docs[1].data(),
            privatedata: docs[2].data(),
          };
          resolve("done");
          dispatch({ type: "FIREBASE_DATA", data: finaldata });
          dispatch({ type: "CHANGE_ROLE", userRole: "admin" });
          dispatch({
            type: "LOGIN_WITH_EMAIL",
            payload: {
              email: user.email,
              name: user.displayName,
              uid: user.uid,
              isSignedIn: true,
              loggedInWith: "firebase",
            },
          });
        })
        .catch((error) => {
          console.log("THere was an error");
          console.log(error);
        });
    });
  };
};

export const resetloginpassword = (email) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      firebaseAuth
        .sendPasswordResetEmail(email)
        .then(function () {
          // Email sent.
          resolve({ e: true });
        })
        .catch(function (error) {
          // An error happened.
          resolve({ e: false, d: error });
        });
    });
  };
};
export const submitLoginWithFireBase = (email, password, remember) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // let userEmail = null,
      //   loggedIn = false
      firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          result.user.updateProfile({
            displayName: "Admin",
          });
          resolve({ e: false, d: "done" });
        })
        .catch((error) => {
          resolve({ e: true, d: error });
          console.log(error);
        });
    });
  };
};

export const loginWithFB = () => {
  return (dispatch) => {
    let provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      display: "popup",
    });
    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        let token = result.credential.accessToken,
          // The signed-in user info.
          user = result.user.email;
        dispatch({
          type: "LOGIN_WITH_FB",
          payload: {
            user,
            uid: result.user.uid,
            token,
            loggedInWith: "firebase",
          },
        });
        // if (user) history.push("/")
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const loginWithTwitter = () => {
  return (dispatch) => {
    let provider = new firebase.auth.TwitterAuthProvider();
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL;
        dispatch({
          type: "LOGIN_WITH_TWITTER",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase",
          },
        });
        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const loginWithGoogle = () => {
  return (dispatch) => {
    let provider = new firebase.auth.GoogleAuthProvider();
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL;
        dispatch({
          type: "LOGIN_WITH_GOOGLE",
          payload: {
            email: user,
            name: name,
            uid: result.user.uid,
            photoUrl,
            token,
            loggedInWith: "firebase",
          },
        });
        // history.push("/")
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const loginWithGithub = () => {
  return (dispatch) => {
    let provider = new firebase.auth.GithubAuthProvider();
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.additionalUserInfo.username,
          photoUrl = result.user.photoURL;

        dispatch({
          type: "LOGIN_WITH_GITHUB",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase",
          },
        });
        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const logoutWithFirebase = (user) => {
  return (dispatch) => {
    console.log("Signoutt");
    firebase
      .auth()
      .signOut()
      .then(
        function () {
          // Sign-out successful.
          console.log("Signed Out");
          dispatch({ type: "CHANGE_ROLE", userRole: "" });

          dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} });
          history.push("/pages/login");
        },
        function (error) {
          // An error happened.
        }
      );
  };
};

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};
