import React, { Component } from "react";
import {
  Tooltip
} from "reactstrap";


class Tooltipview extends Component {
  state = {
    tooltips: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
  };
  // componentWillMount() {
  //   var tt = [];
  //   this.props.data.map((a, i) => {
  //     tt[i] = false;
  //   });
  //   this.setState({
  //     tooltips: tt
  //   })
  // }
  render() {
    return (
      <div>
        {this.props.data.map((a, i) => {
          if (a.id) {
            return (
              <Tooltip
                id={i}
                key={i}
                placement="auto"
                isOpen={this.state.tooltips[i]}
                target={a.id}
                toggle={() => {
                  var newarray = JSON.parse(JSON.stringify(this.state.tooltips));
                  newarray[i] = !this.state.tooltips[i];

                  this.setState({
                    tooltips: newarray
                  })
                }}
              >
                {a.name}
              </Tooltip>
            )
          } else {
            return null;
          }
        })
        }
      </div>
    );
  }
}

export default Tooltipview;