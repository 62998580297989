import React from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import * as Icon from "react-feather"
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";

// import lounge from './lounge.png';
import {
  Badge,
  ListGroup,
  ListGroupItem
} from "reactstrap"
class Contactmodal extends React.Component {
  state = {
    currentlyselected: 0,
    currentdocument: "",
    spinner: true
  };
  componentDidMount() {
    console.log("In documentmodal");
    console.log(this.props.data);
    // console.log(this.props.data[0].url);
    this.setState({
      currentdocument: this.props.data && this.props.data.length > 0 ? this.props.data[0].url : ""
    })
    this.props.onloadcall();
  }
  render() {
    return (
      <React.Fragment>
        {this.props.data && this.props.data.length == 0 ?
          <h2 style={{ textAlign: "center" }}>No Contact Found</h2>
          :
          <Row style={{ height: "100%" }}>
            {this.props.data && this.props.data.length > 0 && this.props.data.map((d, i) => {
              return (
                <Col key={i} lg="12" md="12" sm="12" >
                  <Card >

                    <CardBody className="text-center">
                      <h4>{d.name}</h4>
                      {d.email ? <p>{d.email}</p> : null}
                      {d.position ? <p>{d.position}</p> : null}
                      {d.company ? <p>{d.company}</p> : null}
                      {d.email ?
                        <div className="card-btns d-flex justify-content-center">

                          <Button.Ripple color="primary" className="round" onClick={() => {
                            window.open("mailto:" + d.email)
                            console.log(d.email)
                          }} >
                           Email
                          </Button.Ripple>

                        </div> : null}

                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>}
      </React.Fragment >
    )
  }
}

export default Contactmodal