import React, { Component } from "react";
import Countdown from "react-countdown-now";
import ReactPlayer from 'react-player';
import { Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader, ModalFooter, Button, CardFooter } from "reactstrap";
import csImg from "../../assets/img/pages/rocket.png";
import "../../assets/scss/pages/coming-soon.scss";
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";
// import { Document, Page } from 'react-pdf';
import Documetmodal from "./Documetmodal"
import Videomodel from "./Videomodel";
import Contactmodal from "./Contactmodal";

import Agendatypelist from "./Agendatypelist";

export default class Modalview extends Component {
  state = {
    spinner: true,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    if (nextProps.openmodal !== this.props.openmodal) {
      this.setState({
        spinner: true
      });
    }
  }

  getmodalbody = () => {
    // console.log(this.props);
    switch (this.props.modaltype) {
      case 'pdf':
        return (

          // <div>
          //   <Document
          //     file={this.props.modalsrc}
          //     onLoadSuccess={this.onDocumentLoadSuccess}
          //   >
          //     <Page pageNumber={pageNumber} />
          //   </Document>
          //   <p>Page {pageNumber} of {numPages}</p>
          // </div>
          <iframe title={"title"} onLoad={() => {
            this.setState({ spinner: false })
          }}
            id="vd-vault-1" allow="autoplay; encrypted-media" scrollig="0" width="100%" height="100%" src={this.props.modalsrc}></iframe>

        );
      case 'ivideo':
        return (<iframe title={"title"} onLoad={() => {
          this.setState({ spinner: false })
        }}
          id="vd-vault-1" allow="autoplay; encrypted-media" scrollig="0" width="100%" height="100%" src={this.props.modalsrc}></iframe>
        );
      case 'video':
        console.log("playing video1")
        return (<ReactPlayer className='react-player' style={{ margin: "auto" }} onReady={() => {
          this.setState({ spinner: false })

        }} playing controls={true}
          height='100%'
          width="100%"
          url={this.props.modalsrc} />

        );
      case 'agendatypelist':
        console.log("agendatypelist")
        return (
          <Agendatypelist agendadata={this.props.agendadata} onloadcall={() => {
            this.setState({
              spinner: false
            })
          }} redirecttopage={(url,videolink) => {
            this.props.redirecttopage(url,videolink);
          }} />

        );
      case 'csoon':
        console.log("In coming soon");
        return (
          <Card className="mb-0">
            <CardHeader className="justify-content-center">
              <h2>We are launching soon</h2>
            </CardHeader>
            <CardBody className="text-center">
              <img src={csImg} alt="csImg" className="img-fluid width-150" />
              <div className="text-center getting-started pt-2 d-flex justify-content-center flex-wrap">
                <Countdown
                  date={Date.now() + parseInt(this.props.modalsrc)}
                  renderer={this.renderTimer}
                />
              </div>
            </CardBody>
          </Card>
        )
      case 'docs':
        console.log("showing docs");
        console.log(this.props.modaldata);
        return (
          <Documetmodal data={this.props.modaldata} onloadcall={() => {
            this.setState({
              spinner: false
            })
          }} />
        )
      case 'contacts':
        console.log("showing docs");
        console.log(this.props.modaldata);
        return (
          <Contactmodal data={this.props.modaldata} onloadcall={() => {
            this.setState({
              spinner: false
            })
          }} />
        )
      case 'car_rush':
        return (
          <Card className="mb-0">
            <CardBody className="">


              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3>The Game</h3>

                      Quench your need for speed and throttle the engine to its limit to reach the finish line!
                      <br />
                <br />
                <h3>How to Play</h3>
                      •	The user will be given unlimited trials<br />
•	However, the user will be given one attempt at the final game<br />
•	The users’ have to dodge their car with the traffic and reach the finish line as quickly as possible<br />
•	The time left on the countdown timer after finishing the lap indicates the total score that will get added onto the individual leaderboard<br />

              </div>
            </CardBody>


          </Card >
        )
      case 'jigsaw':
        return (
          <Card className="mb-0">
            <CardBody className="">


              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3>The Game</h3>

                Arrange the bits of Image Puzzle to create final picture and become the ultimate enigmatologist.
                      <br />
                <br />
                <h3>How to Play</h3> <br />
                •	The user will be given unlimited trials <br />
•	However, the user will be given one attempt at the final game <br />
•	The points earned in the final game will be registered and shown on the leaderboard <br />
•	Users will have to arrange the puzzle pieces to get a final picture <br />
•	The time taken to complete the puzzle is recorded<br />
•	Every minute utilized in order to complete the puzzle will result in deducting 10 points out of 100 in the leaderboard <br />
•	For instance, if you complete the puzzle in 2 minutes and 40 seconds, a total of 80 points will be added to your leaderboard <br />
              </div>
            </CardBody>


          </Card >
        )
      case 'connect_4':
        return (
          <Card className="mb-0">
            <CardBody className="">


              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3>The Game</h3>

                Drop your discs into the columns of the game grid and make a line of at least four chips.
                      <br />
                <br />
                <h3>How to Play</h3> <br />
                •	The user will be given unlimited trials <br />
•	However, the user will be given one attempt at the final game <br />
•	The final game will open up only at the time/date mentioned on the game <br />
•	The points earned in the final game will be registered and shown on the leaderboard <br />
•	The user has to connect 4 dots in a straight line in any direction before the computer <br />
•	If the computer connects the 4 dots before the user, then the score will be “Zero” <br />
•	If the user connects the dots before the computer the user gets “100” points <br />
•	If there is a tie, the user will be awarded 50 points <br />
              </div>
            </CardBody>


          </Card >
        )
      case 'battleship_war':
        return (
          <Card className="mb-0">
            <CardBody className="">


              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3>The Game</h3>

                Guess the position of the enemy & Hit all the ships and lead your fleet to the victory!
                      <br />
                <br />
                <h3>How to Play</h3>
                •	The user will be given unlimited trials<br />
•	However, the user will be given one attempt at the final game<br />
•	The final game will open up only at the time/date mentioned on the game<br />
•	The points earned in the final game will be registered and shown on the leaderboard<br />
•	The user will be scored automatically based on the strike rate and performance in the game<br />
•	The total score will be divided by 10<br />
•	Any score above 1000 will be 100<br />
•	If someone scores 672, the total points on the leaderboard will be 67.2 ~ 67 (rounded up)<br />

              </div>
            </CardBody>


          </Card >
        )
      case 'ninja_run':
        return (
          <Card className="mb-0">
            <CardBody className="">


              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3>The Game</h3>

                Define your Ninja way, Duck and Jump to do dodge the shurikens. Get your Ninja gear ready!
                      <br />
                <br />
                <h3>How to Play</h3>
                •	The user will be given unlimited trials <br />
•	However, the user will be given one attempt at the final game <br />
•	The final game will open up only at the time/date mentioned on the game <br />
•	The points earned in the final game will be registered and shown on the leaderboard <br />
•	The user has to dodge the shuriken by either ducking or jumping <br />
•	Ducking in laptop/pc can be done by space bar & in Mobile it can be done by clicking on left side of the screen <br />
•	Jumping in laptop/PC can be done by upper arrow button & in Mobile it can be done by clicking right side of the screen <br />
•	The points will be allotted as per the number of shrunken dodged (mentioned below): <br />
•	1 Shuriken - 1 point <br />
•	10 Shuriken - 10 points <br />
•	20 Shuriken - 20 points <br />
•	100 or more Shuriken - 100 points <br />

              </div>
            </CardBody>


          </Card >
        )
      case 'videolist':

        return (
          <Videomodel data={this.props.modaldata} onloadcall={() => {
            this.setState({
              spinner: false
            })
          }} />
        )
      default:
        return null;
    }
  }
  render() {
    return (
      <Modal
        isOpen={this.props.openmodal}
        toggle={this.props.toggleModal}
        className={`modal-dialog-centered modal-lg ${this.props.modaltype === "docs" || this.props.modaltype === "videolist" || this.props.modaltype === "agendatypelist" ? "modaltest10 " : "modaltest1"}`}
        contentClassName={this.props.modalclass ? this.props.modalclass : "modaltest4"}

      >
        <ModalHeader toggle={this.props.toggleModal}>
          {this.props.modalheader}
        </ModalHeader>
        <ModalBody style={{ overflow: "auto", height: "100%" }}>
          {this.state.spinner ?
            < Spinner /> : null}
          {this.getmodalbody()}
        </ModalBody>

      
        {this.props.modaltype == "car_rush" ?
          <ModalFooter style={{ justifyContent: "center" }}>
            <Button color="primary" className="round"onClick={() => {
              window.open("https://micronhrroadshow.web.app/demo_builds/car_rush/index.html")
            }}>
              Play Trial
        </Button>
            <Button color="primary" className="round"onClick={() => {
              window.open("https://micronhrroadshow.web.app/final_builds/car_rush/index.html")
            }}>
              Play Main Game
        </Button>
          </ModalFooter> : null}
      </Modal>
    );
  }
}