import * as firebase from "firebase/app"
import React from "react"
import { MessageSquare, PlusCircle, Search, XCircle } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import { connect } from "react-redux"
import { Badge, Card, FormGroup, Input } from "reactstrap"
import img from "../../../assets/img/user.png"
import Spinner from "../../../components/@vuexy/spinner/Loading-spinner"
import {
  getChats,
  getContactChats,

  markSeenAllMessages, searchContacts
} from "../../../redux/actions/chat/index"

class ChatSidebar extends React.Component {

  state = {
    chatsContacts: [],
    contacts: [],
    // searchchatcontacts: [],
    messages: [],
    status: null,
    value: "",
    showcontacts: false,
    showloading: true,
    contactlist: [],
    algolia: false,
    // searchcontactlist: []
  }


  async componentDidMount() {
    this.props.getChats().then(() => {
      this.setState({
        showloading: false
      })
    })

  }

  handleOnChange = e => {
    this.setState({ value: e.target.value });
  }
  getcontactlist = () => {
    console.log("Getting contact list");
    firebase.database().ref("pub_user").once("value", snap => {
      console.log(snap.val());
      var tempDoc = []
      Object.keys(snap.val()).map(d => {
        tempDoc.push({ id: d, ...snap.val()[d] })
      })
      // firebase.firestore().collection("pub_user").get().then(snapshots => {
      // const tempDoc = snap.val().map((doc) => {
      //   return { id: doc.id, ...doc.data() }
      // })
      console.log(tempDoc);
      this.setState({
        showloading: false,
        contactlist: tempDoc,
        // searchcontactlist: tempDoc
      })
    }).catch(err => {
      console.log(err);
    })
  }
  getfiltercontacts(array) {
    return array.filter(contact => {
      return contact.name
        .toLowerCase()
        .includes(this.state.value.toLowerCase())
    })
  }
  sortarraybytime(array) {
    return array.sort(function (x, y) {
      return x.chat.lm.time - y.chat.lm.time;
    }).reverse()

  }
  render() {
    var contactsArr = [];
    var chatsArr = [];
    var chatContacts = this.props.chat.chatContacts;
    if (this.state.value.length === 0) {
      contactsArr = this.state.contactlist;
      chatsArr = chatContacts;
    } else {
      contactsArr = this.state.contactlist ? this.getfiltercontacts(this.state.contactlist) : [];
      chatsArr = chatContacts ? this.getfiltercontacts(chatContacts) : [];
    }
    chatsArr = this.sortarraybytime(chatsArr);

    let renderContacts =
      contactsArr.length > 0
        ? contactsArr.map(contact => {
          if (contact.id === firebase.auth().currentUser.uid) {
            return null
          } else {
            return (
              <li
                key={contact.id}
                onClick={() => {
                  this.props.handleActiveChat(

                    contact
                    // chats[contact.id]
                  )
                  // this.props.markSeenAllMessages(contact.uid)
                  this.props.mainSidebar(false)
                }}
              >
                <div className="pr-1">
                  <span className="avatar avatar-md m-0">
                    <img
                      src={contact.pic ? contact.pic : img}
                      alt={contact.name}
                      height="38"
                      width="38"
                    />
                  </span>
                </div>
                <div className="user-chat-info">
                  <div className="contact-info">
                    <h5 className="text-bold-600 mb-0">{contact.name}</h5>
                    {contact.position ?
                      <p className="truncate">{contact.position + " , " + contact.company}</p>
                      :
                      <p className="truncate">{contact.company}</p>}
                  </div>
                </div>
              </li>
            )
          }
        })
        : null
    let renderChats =
      chatsArr
        ? chatsArr.map(chat => {
          // console.log("In for lastmessage");
          // console.log(chat);
          // console.log(chats[chat.id]);
          let lastMsg = chat.chat.lm,
            lastMsgDate = new Date(
              lastMsg && lastMsg.time ? lastMsg.time : null
            ),
            lastMsgMonth = lastMsgDate.toLocaleString("default", {
              month: "short"
            }),
            lastMsgDay = lastMsgDate.getDate();
          // console.log(lastMsg);
          // console.log(lastMsgDate)
          let pendingMsg = chat.chat.us;
          // console.log("Peding msg");
          // console.log(pendingMsg);
          let activeID = chat.id;
          return (
            <li
              key={chat.id}
              onClick={() => {
                this.props.handleActiveChat(chat)
                this.props.mainSidebar(false)
                // this.props.markSeenAllMessages(chat.uid)
              }}
              className={`${
                this.props.activeChatID === chat.id ? "active" : ""
                }`}
            >
              <div className="pr-1">
                <span className="avatar avatar-md m-0">
                  {chat.pic ?
                    <img
                      src={chat.pic}
                      alt={chat.name}
                      height="38"
                      width="38"
                    /> :
                    <img
                      src={img}
                      alt={chat.name}
                      height="38"
                      width="38"
                    />}
                </span>
              </div>
              <div className="user-chat-info">
                <div className="contact-info">
                  <h5 className="text-bold-600 mb-0">{chat.name}</h5>
                  <p className="truncate">
                    {lastMsg ? lastMsg.mes : null}
                  </p>
                  {/* <div className="badge badge-pill badge-danger mt-25 float-right">
                  <span className="align-middle">3</span>
                </div> */}
                </div>
                <div className="contact-meta d-flex- flex-column">
                  <span className="float-right mb-25">
                    {lastMsgMonth + " " + lastMsgDay}
                  </span>
                  {pendingMsg > 0 ? (
                    <div className="unseen-msg">
                      <Badge
                        className="badge-md float-right"
                        color="primary"
                        pill
                      >
                        {pendingMsg}
                      </Badge>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
          )
        })
        : null
    return (
      <Card className="sidebar-content h-100">
        {/* <span
          className="sidebar-close-icon"
          onClick={() => this.props.mainSidebar(false)}
        >
          <X size={15} />
        </span> */}
        <div className="chat-fixed-search">
          <div className="d-flex align-items-center">
            <div className="sidebar-profile-toggle position-relative d-inline-flex">
              <div
                className="avatar"
              // onClick={() => this.props.handleUserSidebar("open")}
              >
                {this.props.user.publicdata.pic ?
                  <img src={this.props.user.publicdata.pic} alt="User Profile" height="40" width="40" /> :
                  <img src={img} alt="User Profile" height="40" width="40" />}
                {/* <span
                  className={
                    status === "dnd"
                      ? "avatar-status-busy"
                      : status === "away"
                      ? "avatar-status-away"
                      : status === "offline"
                      ? "avatar-status-offline"
                      : "avatar-status-online"
                  }
                /> */}
              </div>
            </div>

            <FormGroup className="position-relative has-icon-left mx-1 my-0 w-100">

              <Input
                className="round"
                type="text"
                placeholder="Search contact or start a new chat"
                onChange={e => this.handleOnChange(e)}
                value={this.state.value}
              />
              <div className="form-control-position">
                <Search size={15} />
              </div>

            </FormGroup>
            {this.state.showcontacts ? <XCircle onClick={() => {
              this.setState({
                showcontacts: false
              })
            }} style={{ marginRight: 5, cursor: "pointer" }} size={40} /> :
              <PlusCircle onClick={() => {
                console.log("Show Contacts");
                this.setState({
                  showcontacts: true,
                  showloading: true,
                });
                this.getcontactlist();
              }} style={{ marginRight: 5, cursor: "pointer" }} size={40} />}
          </div>
        </div>
        <PerfectScrollbar
          className="chat-user-list list-group"
          options={{
            wheelPropagation: false
          }}
        >
          {this.state.showcontacts ?
            <div>
              <h3 className="primary p-1 mb-0">Contacts</h3>
              {this.state.showloading ?
                <Spinner /> :
                <ul className="chat-users-list-wrapper media-list">
                  {renderContacts}
                </ul>}</div> : <>
              <h3 className="primary p-1 mb-0">Chats</h3>
              {this.state.showloading ?
                <Spinner /> :
                chatsArr.length > 0 ?
                  <ul className="chat-users-list-wrapper media-list" style={{ paddingBottom: 60 }}>{renderChats}</ul>
                  :
                  <div className="chat-app-window">
                    <div
                      className={'start-chat-area d-flex'} style={{ height: "calc(100vh - 13rem)" }}>
                      <span className="mb-1 start-chat-icon">
                        <MessageSquare size={50} />
                      </span>
                      <h4
                        className="py-50 px-1 sidebar-toggle start-chat-text"
                        onClick={() => {
                          this.setState({
                            showcontacts: true,
                            showloading: true,
                          });
                          this.getcontactlist();
                          // this.props.mainSidebar(true)
                          // if (this.props.mql.matches === false) {
                          //   this.props.mainSidebar(true)
                          // } else {
                          //   return null
                          // }
                        }}>
                        Start Conversation
              </h4>
                    </div>
                  </div>
              }
            </>}

        </PerfectScrollbar>
      </Card>
    )
  }
}

const mapStateToProps = state => {
  return {
    chat: state.chatApp.chats,
    user: state.auth.login.data
  }
}
export default connect(mapStateToProps, {
  getChats,
  getContactChats,
  searchContacts,
  markSeenAllMessages
})(ChatSidebar)
