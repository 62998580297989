import classnames from "classnames"
import React from "react"
import enhanceWithClickOutside from 'react-click-outside'
// import Radio from "../radio/RadioVuexy"
import * as Icon from "react-feather"
// import { CustomInput } from "reactstrap"
import { X } from "react-feather"
import { connect } from "react-redux"
import { Tooltip } from "reactstrap"
import "../../../assets/scss/components/customizer.scss"
import { changecurrenchatuser } from "../../../redux/actions/chat/index"
// import PerfectScrollbar from "react-perfect-scrollbar"
import { ContextLayout } from "../../../utility/context/Layout"
// const chat = lazy(() => import("./views/apps/chat/Chat"))
// const chat = lazy(() => import("../../../views/apps/chat/Chat"))
import Chat from "../../../views/apps/chat/Chat"


class Customizer extends React.Component {
  state = {
    activeNavbar: this.props.activeNavbar,
    navbarType: null,
    footerType: null,
    menuTheme: null,
    collapsedSidebar: null,
    tooltip: false
  }

 
  componentDidMount() {
    this.setState({
      navbarType: this.props.navbarType,
      footerType: this.props.footerType,
      menuTheme: this.props.menuTheme,
      activeMode: this.props.activeMode,
      collapseSidebar: this.props.sidebarState,
      tooltip: true
    })
  }
  handleNavbarChange = color => {
    this.props.changeNavbar(color)
    this.setState({
      activeNavbar: color
    })
  }
  handleClickOutside() {
    console.log("Outside click called");
    this.props.offallcustomizer();
  }


  render() {

    return (
      <ContextLayout.Consumer>
        {context => {
          return (
            <div
              style={{
                zIndex: 10000
              }}
              className={classnames("customizer d-md-block", {
                open: this.props.customizerState === true
              })}
            >
              {/* <div
                id="chattooltip"
                className="customizer-toggle"
                onClick={() =>
                  this.props.handleCustomizer(!this.props.customizerState)
                }
              >
                
                <div>
                <div style={{ marginTop: 5 }}>CHAT</div>
                
                </div>
             
              </div> */}
            
              <div className="header d-flex justify-content-between px-2 pt-2" style={{ paddingBottom: "1.5rem" }}>

                <div className="title">
                  <h4 className="text-uppercase mb-0">Chat</h4>
                  {/* <small>Customize & Preview in Real Time</small> */}
                </div>
                <div
                  className="close-icon cursor-pointer"
                  onClick={() => {
                    this.props.handleCustomizer(false)
                    this.props.changecurrenchatuser(null);
                  }}
                >
                  <X size={24} />
                </div>
              </div>
              {/* <hr /> */}
              {/* <iframe title="Participant view" height="100%" width="100%" src="https://app.sli.do/event/hwtfein6"></iframe> */}

              <Chat />
            </div>
          )
        }}
      </ContextLayout.Consumer>
    )
  }
}
// collapseSidebar
const mapStateToProps = state => {
  return {
    sidebarCollapsed: state.customizer.customizer.sidebarCollapsed,
    totalnonread: state.chatApp.chats.totalnonread
  }
}
// export default (connect(mapStateToProps, null)(Customizer))
export default enhanceWithClickOutside(connect(mapStateToProps, { changecurrenchatuser }, null, { forwardRef: true })(Customizer));